<template>
    <v-card flat class="mx-auto my-12" max-width="90vw">
        <v-tabs v-model="tab" hide-slider>
            <v-tab v-for="item in items" :key="item.tab" class="text-h5" :to="{ name: item.link, query: $route.query  }">
                <v-icon right v-if="item.icon && item.icon != ''">{{
                    item.icon
                }}</v-icon>
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-divider color="#512DA8" class="mb-3"></v-divider>
        <router-view></router-view>
    </v-card>
</template>
<script>
export default {
    name: 'Admin',
    data: () => ({
        tab: null,
        dateCreated: new Date(),
        items: [
            {
                tab: "סוגי שדות",
                icon: "mdi-form-textbox",
                link: "NewPostComponents",
            },
            {
                tab: "סוגי מודעות",
                icon: "mdi-text-box-outline",
                link: "NewPostTypes",
            },
            {
                tab: "קוטרות בעמוד הראשי",
                icon: "mdi-text-box-outline",
                link: "MainPageHeaders",
            },
            {
                tab: "META DATA",
                icon: "mdi-text-box-outline",
                link: "MetaData",
            },
            {
                tab: "עמודי מידע",
                icon: "mdi-information-outline",
                link: "infoPages",
            },
            {
                tab: "Images",
                icon: "mdi-image",
                link: "Images",
            }

        ],
    }),
}

</script>

<style scoped>
.v-tab--active {
  border: #1565C0;
  background-color: #1565C0;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  color: white !important;
}
.v-divider {
  border-top-width: 1px;
}
</style>